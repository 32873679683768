// @flow
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper/Paper";
import Table from "@material-ui/core/Table/Table";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody/TableBody";
import TableRow from "@material-ui/core/TableRow/TableRow";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import './dashboard.css';
import type {Build, Container, ContainerState, Deployment, Pod, Release} from "../../App";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import {
  Apps as AppMainIcon,
  Assessment as AppLoadIcon,
  BugReport as AppDebugIcon,
  Storage as AppMigrationsIcon,
  ThreeSixty as AppE2eIcon
} from '@material-ui/icons';
import TimeAgo from "react-timeago";
import Chip from "@material-ui/core/Chip";
import Icon from '@mdi/react'
import {mdiGithub, mdiMicrosoftAzureDevops} from '@mdi/js';

interface Options {
  lexicographical: boolean;
  zeroExtend: boolean;
}

const options: Options = {
  lexicographical: false,
  zeroExtend: false
};

interface Colours {
  Default: string;
  White: string;
  LightOrange: string;
  Orange: string;
  DarkOrange: string;
  LightRed: string;
  Red: string;
  DarkRed: string;
  LightGreen: string;
  Green: string;
  DarkGreen: string;
  LightBlue: string;
  Blue: string;
  DarkBlue: string;
}

const colors: Colours = {
  Default: "",
  White: "#ffffff",
  LightOrange: "#fff4e5",
  Orange: "#ff9800",
  DarkOrange: "#663c00",
  LightRed: "#fdecea",
  Red: "#f44336",
  DarkRed: "#611a15",
  LightGreen: "#edf7ed",
  Green: "#4caf50",
  DarkGreen: "#1e4620",
  LightBlue: "#e8f4fd",
  Blue: "#2196f3",
  DarkBlue: "#0d3c61",
}

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  chip: {
    margin: '0 2px',
    fontSize: "11px",
  },
  azure: {
    margin: '0 2px',
  },
  version: {
    margin: '0 2px',
  },
  grow: {
    flexGrow: 1,
  },
  icon: {
    fontSize: "14px",
    float: "left",
    marginRight: "5px",
  },
  cell: {
    fontSize: "11px",
  },
});

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.renderStateTooltip = this.renderStateTooltip.bind(this);
    this.renderPodType = this.renderPodType.bind(this);
    this.renderTitle = this.renderTitle.bind(this);
    this.renderBuildInfo = this.renderBuildInfo.bind(this);
    this.renderLowerDeploymentBuildInfo = this.renderLowerDeploymentBuildInfo.bind(this);
    this.renderReleaseInfo = this.renderReleaseInfo.bind(this);
    this.renderVersionInfo = this.renderVersionInfo.bind(this);
    this.renderReplicaInfo = this.renderReplicaInfo.bind(this);
    this.versionCompare = this.versionCompare.bind(this);
    this.renderContainerState = this.renderContainerState.bind(this);
  }

  renderStateTooltip(state: ContainerState) {
    if (state.waiting) {
      return (
        <div>
          Reason: {state.waiting.reason}<br/>
          Message: {state.waiting.message}
        </div>
      )
    }

    if (state.running) {
      return (
        <div>
          Started: <TimeAgo date={state.running.started_at}/>
        </div>
      )
    }

    if (state.terminated) {
      return (
        <div>
          Exit code: {state.terminated.exit_code}<br/>
          Signal: {state.terminated.signal}<br/>
          Reason: {state.terminated.reason}<br/>
          Message: {state.terminated.message}<br/>
          Started: <TimeAgo date={state.terminated.started_at}/><br/>
          Finished: <TimeAgo date={state.terminated.finished_at}/>
        </div>
      )
    }
  }

  renderPodType(podType: string) {
    const {classes} = this.props;

    switch (podType) {
      case "MAIN":
        return <AppMainIcon className={classes.icon}></AppMainIcon>
      case "E2E":
        return <AppE2eIcon className={classes.icon}></AppE2eIcon>
      case "MIGRATIONS":
        return <AppMigrationsIcon className={classes.icon}></AppMigrationsIcon>
      case "LOAD":
        return <AppLoadIcon className={classes.icon}></AppLoadIcon>
      default:
        return <div></div>
    }

  }

  renderReleaseInfo(release: Release) {
    if (typeof release === "undefined") {
      return
    }

    if (!release.version) {
      return
    }

    const {classes} = this.props;

    return (
      <Tooltip
        title={`Latest release: ${release.version}`}
        className={classes.azure}
      >
        <a
          target={"_blank"}
          rel="noopener noreferrer"
          href={release.url}
        >
          <Icon
            path={mdiGithub}
            size={0.75}
            color={"black"}
          />
        </a>
      </Tooltip>
    )
  }

  renderLowerDeploymentBuildInfo(d: Deployment) {
    if (typeof d === "undefined") {
      return
    }

    if (typeof d.lowerDeployment === "undefined") {
      return
    }

    let lowerDeployment: Deployment = d.lowerDeployment

    if (d.version === lowerDeployment.version) {
      return
    }

    if (typeof lowerDeployment.build === "undefined") {
      return
    }

    let build: Build = lowerDeployment.build

    if (!build.status) {
      return
    }

    const {classes} = this.props;

    let buildTitle = `Build status for release ${lowerDeployment.version}: ${build.status}`
    if (build.result) {
      buildTitle += ` (${build.result})`
    }

    let buildColor = ""
    switch (build.status) {
      case "cancelling":
        buildColor = colors.Red
        break
      case "inProgress":
        buildColor = colors.Blue
        break
      default:
    }

    if (build.result !== "") {
      switch (build.result) {
        case "canceled":
        case "failed":
          buildColor = colors.Red
          break
        case "succeeded":
          buildColor = colors.Green
          break
        default:
      }
    }

    return (
      <Tooltip
        title={buildTitle}
        className={classes.azure}
      >
        <a
          target={"_blank"}
          rel="noopener noreferrer"
          href={build.url}
        >
          <Icon
            path={mdiMicrosoftAzureDevops}
            size={0.75}
            color={buildColor}
          />
        </a>
      </Tooltip>
    )
  }

  renderBuildInfo(build: Build) {
    if (typeof build === "undefined") {
      return
    }

    if (!build.status) {
      return
    }

    let buildTitle = `Build status: ${build.status}`
    if (build.result) {
      buildTitle += ` (${build.result})`
    }

    let buildColor = ""
    switch (build.status) {
      case "cancelling":
        buildColor = colors.Red
        break
      case "inProgress":
        buildColor = colors.Blue
        break
      default:
    }

    if (build.result !== "") {
      switch (build.result) {
        case "canceled":
        case "failed":
          buildColor = colors.Red
          break
        case "succeeded":
          buildColor = colors.Green
          break
        default:
      }
    }

    return (
      <Tooltip
        title={buildTitle}
        placement="top"
      >
        <a
          target={"_blank"}
          rel="noopener noreferrer"
          href={build.url}
        >
          <Icon
            path={mdiMicrosoftAzureDevops}
            size={0.75}
            color={buildColor}
          />
        </a>
      </Tooltip>
    )
  }

  renderVersionInfo(d: Deployment) {
    if (typeof d === "undefined") {
      return
    }

    if (!d.version) {
      return
    }

    const {classes} = this.props;

    let chipBG = ""
    let chipColor = ""
    let title = "Running version"

    if (d.lowerDeployment) {
      const comparedVersions = this.versionCompare(d.version, d.lowerDeployment.version, options);
      switch (comparedVersions) {
        case -1:
          chipBG = colors.LightOrange
          chipColor = colors.DarkOrange
          title = `Upgrade from ${d.version} to ${d.lowerDeployment.version} is available`
          break
        case 0:
          chipBG = colors.LightGreen
          chipColor = colors.DarkGreen
          title = `Running version ${d.version} is up to date`
          break
        case 1:
          chipBG = colors.LightRed
          chipColor = colors.DarkRed
          title = `Running version ${d.version} is newer than lower deployed version ${d.lowerDeployment.version}`
          break
        default:
      }
    }

    return (
      <Tooltip
        title={title}
        className={classes.azure}
      >
        <Chip
          icon={this.renderBuildInfo(d.build)}
          label={d.version}
          className={classes.chip}
          style={{
            backgroundColor: chipBG,
            color: chipColor
          }}
        />
      </Tooltip>

    )
  }

  renderReplicaInfo(r: Number) {
    const {classes} = this.props;

    if (typeof r === "undefined") {
      return
    }

    let chipBG = ""
    let chipColor = ""

    if (r === 0) {
      chipBG = colors.LightRed
      chipColor = colors.DarkRed
    } else {
      chipBG = colors.LightGreen
      chipColor = colors.DarkGreen
    }

    return (
      <Tooltip title={`${r} replica(s)`}>
        <Chip
          label={r}
          className={classes.chip}
          style={{
            backgroundColor: chipBG,
            color: chipColor
          }}
        />
      </Tooltip>
    )
  }

  renderTitle(d: Deployment) {
    const {classes} = this.props;

    let chipBG = ""
    let chipColor = ""
    let title = ""

    let totalPods = 0
    Object.keys(d.pods).forEach((pKey: string) => {
      let pod: Pod = this.props.deployment.pods[pKey]
      if (pod.type === "MAIN") {
        totalPods++
      }
    })

    if (!d.replicas || !d.pods) {

    } else if (d.replicas !== totalPods) {
      chipBG = colors.LightBlue
      chipColor = colors.DarkBlue
      title = "Deploying"
    } else if (d.replicas === totalPods) {
      chipBG = colors.LightGreen
      chipColor = colors.DarkGreen
      title = "Running"
    }

    return (
      <div className={classes.grow}>
        <Tooltip title={title}>
          <Chip
            label={d.name}
            className={classes.chip}
            style={{
              backgroundColor: chipBG,
              color: chipColor
            }}
          />
        </Tooltip>
      </div>
    )
  }

  renderContainerState(container: Container) {
    if (!container || !container.state) {
      return
    }

    if (container.state.terminated) {
      return (` (${container.state.terminated.reason})`)
    }

    if (container.state.waiting) {
      return (` (${container.state.waiting.reason})`)
    }
  }

  versionCompare(v1, v2, options: Options) {
    let lexicographical = (options && options.lexicographical) || false,
      zeroExtend = (options && options.zeroExtend) || true,
      v1parts = (v1 || "0").split('.'),
      v2parts = (v2 || "0").split('.');

    function isValidPart(x) {
      return (lexicographical ? /^\d+[A-Za-zαß]*$/ : /^\d+[A-Za-zαß]?$/).test(x);
    }

    if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
      return NaN;
    }

    if (zeroExtend) {
      while (v1parts.length < v2parts.length) v1parts.push("0");
      while (v2parts.length < v1parts.length) v2parts.push("0");
    }

    if (!lexicographical) {
      v1parts = v1parts.map(function (x) {
        let match = (/[A-Za-zαß]/).exec(x);
        return Number(match ? x.replace(match[0], "." + x.charCodeAt(match.index)) : x);
      });
      v2parts = v2parts.map(function (x) {
        let match = (/[A-Za-zαß]/).exec(x);
        return Number(match ? x.replace(match[0], "." + x.charCodeAt(match.index)) : x);
      });
    }

    for (let i = 0; i < v1parts.length; ++i) {
      if (v2parts.length === i) {
        return 1;
      }

      if (v1parts[i] === v2parts[i]) {
        continue;
      } else if (v1parts[i] > v2parts[i]) {
        return 1;
      } else {
        return -1;
      }
    }

    if (v1parts.length !== v2parts.length) {
      return -1;
    }

    return 0;
  }

  render() {
    const {classes} = this.props;
    return (
      <Paper className={classes.root}>
        <AppBar position="relative">
          <Toolbar variant={'dense'}>
            {this.renderTitle(this.props.deployment)}
            {this.renderReleaseInfo(this.props.deployment.release)}
            {this.props.deployment && this.renderLowerDeploymentBuildInfo(this.props.deployment)}
            {this.renderVersionInfo(this.props.deployment)}
            {this.renderReplicaInfo(this.props.deployment.replicas)}
          </Toolbar>
        </AppBar>
        {
          this.props.deployment.pods &&
          <Table size={"medium"}>
            <TableBody>
              {
                Object.keys(this.props.deployment.pods).map((pKey: string) => {
                  let pod: Pod = this.props.deployment.pods[pKey]
                  return (
                    <TableRow key={pKey}>
                      <TableCell component="th" scope="row" className={classes.cell}>
                        {
                          pod.container &&
                          <Tooltip title={pod.type}>
                            {this.renderPodType(pod.type)}
                          </Tooltip>
                        }
                        <Tooltip title={pod.name}>
                          <div>
                            <span>{pod.phase}</span>
                            {this.renderContainerState(pod.container)}
                          </div>
                        </Tooltip>
                      </TableCell>
                      {
                        pod.container &&
                        <TableCell component="th" scope="row" align={"right"} className={classes.cell}>
                          <Tooltip title={this.renderStateTooltip(pod.container.state)}>
                            <span>{pod.container.version}</span>
                          </Tooltip>
                          {
                            pod.container.debug &&
                            <Tooltip title={"This container is using the alpine debug image"}>
                              <AppDebugIcon
                                style={{
                                  float: "right",
                                  marginLeft: "5px",
                                  fontSize: "15px",
                                }}
                              ></AppDebugIcon>
                            </Tooltip>
                          }
                        </TableCell>
                      }
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        }
      </Paper>
    );
  }
}

Dashboard.propTypes = {
  deployment: PropTypes.object.isRequired,
};

export default withStyles(styles)(Dashboard);
